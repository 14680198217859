import FormPage from '@/components/FormPage'
import security from '@/security'
import { createGetParams, createPostParams } from '@/rest'

const formTitle = 'Transfer Pin'
const defaultPin = 'D225'

export default {
  extends: FormPage,
  data () {
    return {
      name: null,
      street1: null,
      street2: null,
      city: null,
      postalCode: null,
      state: null,
      breadcrumbs: [
        {
          text: 'Dealer Kits', disabled: false, to: '/dealer_kits',
        },
        {
          text: 'Dealership', disabled: false, to: '/dealership_kits',
        },
        {
          text: formTitle, disabled: true,
        },
      ],
      title: formTitle,
      // Form steps
      transferDetails: {
        fields: {
          d225Pin: {
            name: defaultPin + ' balance',
          },
          dpPin: {
            name: 'Dealer (DP) balance',
          },
          dealerPin: {
            name: 'Dealer Pin balance',
          },
          bPin: {
            name: 'BPin balance',
          },
          sdPin: {
            name: 'SD Pin balance',
          },
          nuPin: {
            name: 'NU Pin balance',
          },
          pinType: {
            component: () => import(/* webpackChunkName: "dealerTypeField" */ '@/components/DealerTypeField'),
            name: 'Transfer Pin of type',
            list: [],
            props: {
              label: 'Select transfer Pin type',
              required: true,
              rules: [val => !!val || 'Dealer type required!'],
            },
            value: defaultPin,
          },
          dealer: {
            component: () => import(/* webpackChunkName: "dealerField" */ '@/components/DealerField'),
            name: 'To dealer Id',
            props: {
              persistentHint: true,
              label: 'Enter Dealer Id',
              noSelf: true,
              claimer: security.me.claimer(),
              required: true,
              rules: [val => !!val || 'Dealer Id required!'],
            },
          },
          quantity: {
            component: () => import(/* webpackChunkName: "quantityField" */ '@/components/QuantityField'),
            name: 'Quantity',
            props: {
              label: 'Enter quantity',
              required: true,
              rules: [val => !!val || 'Quantity required!'],
            },
          },
        },
        title: 'Pin transfer details',
      },
      submission: {
        fields: {
          pinType: {
            name: 'Transfer Pin of type',
          },
          dealer: {
            name: 'To dealer Id',
          },
          quantity: {
            emphasize: true,
            name: 'Quantity',
          },
        },
        submit: false,
        errorMessage: null,
        status: null,
        subTitle: 'Check before submit',
        title: 'Submit',
      },
    }
  },
  computed: {
    formSteps: function () {
      return {
        transferDetails: this.transferDetails,
        submission: this.submission,
      }
    },
  },
  mounted: function () {
    const params = createGetParams({
      dealership_pin: true,
    })
    this.$rest.get('getDealerResources.php', params).then(response => {
      if (security.me.pureNuDealer()) {
        this.transferDetails.fields.nuPin.value = response.data.nuPin
        this.transferDetails.fields.pinType.list = ['NU']
        this.transferDetails.fields.pinType.value = 'NU'
        this.transferDetails.fields.bPin = {}
        this.transferDetails.fields.sdPin = {}
        this.transferDetails.fields.dealerPin = {}
        this.transferDetails.fields.d225Pin = {}
        this.transferDetails.fields.dpPin = {}
        this.transferDetails.fields.pinType.props.label = ''
      } else {
        this.transferDetails.fields.d225Pin.value = response.data.d225Pin ? response.data.d225Pin : 0
        this.transferDetails.fields.pinType.list = [defaultPin]
        if (response.data.bPin || response.data.sdPin || response.data.dealerPin || response.data.dpPin) {
          if (response.data.dpPin) {
            this.transferDetails.fields.dpPin.value = response.data.dpPin
            this.transferDetails.fields.pinType.list.push('Dealer (DP)')
          } else {
            this.transferDetails.fields.dpPin = {}
          }
          if (response.data.dealerPin) {
            this.transferDetails.fields.dealerPin.value = response.data.dealerPin
            this.transferDetails.fields.pinType.list.push('Dealer')
          } else {
            this.transferDetails.fields.dealerPin = {}
          }
          if (response.data.bPin) {
            this.transferDetails.fields.bPin.value = response.data.bPin
            this.transferDetails.fields.pinType.list.push('BPin')
          } else {
            this.transferDetails.fields.bPin = {}
          }
          if (response.data.sdPin) {
            this.transferDetails.fields.sdPin.value = response.data.sdPin
            this.transferDetails.fields.pinType.list.push('Subdealer')
          } else {
            this.transferDetails.fields.sdPin = {}
          }
        } else {
          this.transferDetails.fields.dpPin = {}
          this.transferDetails.fields.bPin = {}
          this.transferDetails.fields.sdPin = {}
          this.transferDetails.fields.dealerPin = {}
          this.transferDetails.fields.pinType.props.label = ''
        }
        if (security.me.nuDealer()) {
          this.transferDetails.fields.nuPin.value = response.data.nuPin
          this.transferDetails.fields.pinType.list.push('NU')
        } else {
          this.transferDetails.fields.nuPin = {}
        }
      }
    })
    this.transferDetails.fields.quantity.props.rules.push(val => this.validateQuantity(val))
    this.submission.fields.pinType.value = this.transferDetails.fields.pinType.value
  },
  watch: {
    'transferDetails.fields.dealer.value': function (val) {
      this.submission.fields.dealer.value = val
    },
    'transferDetails.fields.quantity.value': function (val) {
      this.submission.fields.quantity.value = val
    },
    'transferDetails.fields.pinType.value': function (val) {
      this.submission.fields.pinType.value = val
    },
  },
  methods: {
    validateQuantity: function (val) {
      if (Number.isNaN(val) || Number(val) === 0) {
        return 'Invalid quantity'
      }

      val = Number(val)
      if (this.transferDetails.fields.pinType.value === 'Dealer') {
        if (val > this.transferDetails.fields.dealerPin.value) {
          return 'Insufficient Dealer Pin!'
        }
      } else if (this.transferDetails.fields.pinType.value === 'BPin') {
        if (val > this.transferDetails.fields.bPin.value) {
          return 'Insufficient BPin!'
        }
      } else if (this.transferDetails.fields.pinType.value === 'Subdealer') {
        if (val > this.transferDetails.fields.sdPin.value) {
          return 'Insufficient SD Pin!'
        }
      } else if (this.transferDetails.fields.pinType.value === 'NU') {
        if (val > this.transferDetails.fields.nuPin.value) {
          return 'Insufficient NU Pin!'
        }
      } else if (this.transferDetails.fields.pinType.value === 'Dealer (DP)') {
        if (val > this.transferDetails.fields.dpPin.value) {
          return 'Insufficient Dealer (DP) Pin!'
        }
      } else {
        if (val > this.transferDetails.fields.d225Pin.value) {
          return 'Insufficient ' + defaultPin + ' Pin!'
        }
      }

      return true
    },
    // Override
    async submit () {
      const type = this.transferDetails.fields.pinType.value === 'Dealer' ? 'D'
        : (this.transferDetails.fields.pinType.value === 'Subdealer' ? 'SD'
          : (this.transferDetails.fields.pinType.value === 'BPin' ? 'B'
            : (this.transferDetails.fields.pinType.value === 'NU' ? 'NU'
              : (this.transferDetails.fields.pinType.value === 'Dealer (DP)' ? 'DP' : 'D225'))))
      const params = createPostParams({
        action: 'transferDealershipPin',
        payload: {
          type: type,
          target: this.transferDetails.fields.dealer.value,
          quantity: this.transferDetails.fields.quantity.value,
        },
      })

      try {
        const response = await this.$rest.post('postAction.php', params)
        this.$nextTick(() => {
          this.alertText = 'Your request to transfer dealership PIN was successfully submitted.'
          this.status = this.submission.status = 'success'
          this.submission.submit = false
          this.isImpersonated() && this.showSubmissionResponse(response.data)
        })
      } catch (e) {
        this.$nextTick(() => {
          this.status = this.submission.status = 'error'
          this.submission.errorMessage = e.message + ': ' + e.response.data
          this.submission.submit = false
        })
      }
    },
  },
}
